import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import TurningData from "../Turning/TurningData";
import question from "../../images/Cess23-24.png";
import coreImage from "../../images/member_pic/2024/core.png";
import upcoming from "../../images/Timeline.png";
import Preface from "../highlights/Preface";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import vid from "../../images/vide.mp4";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Section2.css"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Section2 = () => {
  return (<>
    <Box className="mt-9" my={8} p={5}>
      <Container>
        <Typography
          variant="h3"
          component="p"
          className="dsc-font"
          align="center"
        >
          Our <span className="color-primary">Values</span>
        </Typography>

      </Container>
      <Grid container>
        <Grid sm={12} md={5} lg={5} data-aos="zoom-in" style={{ margin: "1rem" }}>
          <Typography variant="h5" component="p" className="dsc-font">
            From President's Desk   </Typography>
          <Typography variant="body1" component="p" align="justify" className="dsc-font">  " Engineering should never be limited to the boundaries of university curriculum. It should flourish by developing our technical as well as non-technical skills. Today’s industries, not only demands grades but up-to-date skills and an overall personality.  I believe in communities like RGIT CESS to be the bridge between students and the ever-changing IT industry. RGIT-CESS has always proven to be a stage for untapped talents to explore, improvise and showcase themselves.
            Our initiatives over the past years have proven to be of great help to our students. With programs like Hackathon, Reach & Teach, CESS got Talent we as students are getting real world experience, giving us an edge for the upcoming challenges.
            “Aspire for a better tomorrow by challenging yourself today !!”
            <Typography variant="body1" component="p" className="dsc-font">
              Mr. Santa Kumar Pulipaka </Typography>
            <Typography variant="body1" component="p" className="dsc-font">
              President, RGIT-CESS
            </Typography>
          </Typography>
        </Grid>
        <Grid item sm={12} md={12} lg={6}><center>
          <img
            src={coreImage}
            alt="searching"
            // height="400px"
            width="100%"
            style={{ borderRadius: "1rem" }}
          ></img></center>
        </Grid>
      </Grid>

      <Box className="about-dsc-div" p={5}>
        <Container>
          <Grid container spacing={4}>

            <Grid
              data-aos="fade-up"
              data-aos-duration="500"
              item
              lg={3}
              md={3}
              sm={12}
            >
              <Typography
                variant="h5"
                component="p"
                className="dsc-font"
                align="center"
              >
                <span className="color-primary bg-primary">Create</span>
              </Typography>
              <Typography variant="body1" component="p" className="dsc-font">
                <center> A platform where you can build yourself.</center>
              </Typography>
            </Grid>
            <Grid
              data-aos="fade-up"
              data-aos-duration="800"
              item
              lg={3}
              md={3}
              sm={12}
            >
              <Typography
                variant="h5"
                component="p"
                className="dsc-font"
                align="center"
              >
                <span className="color-success bg-success">Educate</span>
              </Typography>
              <Typography variant="body1" component="p" className="dsc-font">
                <center> A place to seek and impart knowledge.</center>
              </Typography>
            </Grid>

            <Grid
              data-aos="fade-up"
              data-aos-duration="1100"
              item
              lg={3}
              md={3}
              sm={12}
            >
              <Typography
                variant="h5"
                component="p"
                className="dsc-font"
                align="center"
              >
                <span className="color-warning bg-warning">StandOut</span>
              </Typography>
              <Typography variant="body1" component="p" className="dsc-font">
                <center> A place to seek and impart knowledge.</center>
              </Typography>
            </Grid>

            <Grid
              data-aos="fade-up"
              data-aos-duration="1400"
              item
              lg={3}
              md={3}
              sm={12}
            >
              <Typography
                variant="h5"
                component="p"
                className="dsc-font"
                align="center"
              >
                <span className="color-danger bg-danger">Success</span>
              </Typography>
              <Typography variant="body1" component="p" className="dsc-font">
                <center> A place to seek and impart knowledge.</center>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>{/* 
      <img src={olt} width="100%" height="500" /> */}
      <center><Grid item sm={12} md={12} lg={6}>
        <video width="100%" height="500" autoPlay controls>
          <source src={vid} type="video/mp4" />
        </video>

      </Grid>
      </center>
      <br></br><br></br>

      <Preface />
    </Box>
    <Container>
      <Typography
        variant="h3"
        component="p"
        className="dsc-font"
        align="center"
      >
        Latest From <span className="color-primary">Turing</span>
      </Typography>
    </Container>
    <Carousel responsive={responsive}>
      {
        TurningData.map((tr, key) => (
          <>
            <div>  <Typography
              variant="h5"
              component="p"
              className="dsc-font"
              align="center"
            >
              <span className="color-primary bg-primary">{tr.turningTitle}</span>
            </Typography>
              <center><a href={`/#/Turingview/${tr.turningLink}`} target='_blank' rel='noopener noreferrer'>  <img alt="2022" src={tr.turningImage} height="250px" width="200px" /></a></center>
            </div>
          </>
        ))
      }

    </Carousel>


    <Container style={{ marginTop: "4rem" }}>
      <Typography
        variant="h3"
        component="p"
        className="dsc-font"
        align="center"
      >
        <span className="color-primary">Events</span>  conducted this year
      </Typography>

    </Container>
    <Container>
      <Grid container>
        {/*  <Grid item md={6} sm={12}>
          <Typography variant="h4" component="p" className="dsc-font">
          CYBERSECURITY AWARNESS ON   <span className="color-primary">CYBERCRIME & WOMEN SAFETY</span>
          </Typography>
          <Typography variant="h5" component="p" className="dsc-font">
          ( CAREER IN CYBERSECURITY ) 
          </Typography>

          <Typography variant="p" component="p" className="dsc-font">
          DATE:- 28TH September
          <br/>
          Session 1: 9:00-10:30 a.m
          <br/>
          Session 2: 11:00-12:30 p.m
          <br/>
          Session 3: 1:00-2:30 p.m
          </Typography>
          <Typography variant="h6" component="p" className="dsc-font">
          <span className="color-primary"> Speaker:-MR. ANSH BHIDE </span> <br/> Sr.Cybersecurity Instructor at MrWebsecure Info solution pvt ltd.</Typography>
          <h4><ul>
            <li>Introduction to hacking</li>
            <li>Footprinting</li>
            <li>Google hacking</li>
            <li>Xss</li>
            <li> Sql injection</li>
            <li>Phising</li>
            <li>etc....</li>
          </ul></h4>
        </Grid> */}
        <Grid item sm={12} md={12} lg={12}>
          <img
            // data-aos="fade-top"
            // className="timeline"
            style={{ width: "100%", objectFit: 'cover' }}
            src={upcoming}
            alt="searching"
          ></img>
        </Grid>
      </Grid>
    </Container>
  </>
  );
};

export default Section2;
