import React from "react";
import Prizecard from "./prizecard";
import { Typography, Grid } from "@material-ui/core";

export default function Prizes(props) {
  const prizeArr = [
    {
      // "image": "linkedin",
      image: "e1",
      "heading": "LinkedIn and Resume Mastery",
      "desc": [
        "Date & Timing: On 4th October 2023 at 1:30 pm",
        "The 'LinkedIn & Resume Mastery' guided students on improving their LinkedIn profiles and resumes to enhance career prospects.",
        "Venue: Classroom"
      ]
    },
    {
      // "image": "hacking",
      image: "e1",
      "heading": "Unexplored Secret of Ethical Hacking",
      "desc": [
        "Date & Timing: On 12th October 2023 at 1 pm",
        "The event covered career opportunities in cybersecurity with Mr. Gaurav Kumar, a Certified Ethical Hacker and CEO of The Tech Uniques.",
        "Participants received insights into ethical hacking and certificates from The Tech Uniques.",
        "Speaker: Mr. Gaurav Kumar",
        "Venue: Seminar Hall"
      ]
    },
    {
      // "image": "study_abroad",
      image: "e1",
      "heading": "Genius Creation Study Abroad Webinar",
      "desc": [
        "Date & Timing: On 14th October 2023 at 11 am",
        "The webinar provided insights into studying abroad, covering fields like data science, cybersecurity, and more.",
        "Speaker: Ms. Lalita Datar",
        "Venue: Seminar Hall"
      ]
    },
    {
      // "image": "data_science",
      image: "e1",
      "heading": "Diving into Data Science Basics",
      "desc": [
        "Date & Timing: On 17th October 2023 at 2 pm",
        "The seminar introduced the basics of data science, including machine learning, tools, and career paths.",
        "Speaker: Ms. Nikita Tandel",
        "Venue: Seminar Hall"
      ]
    },
    {
      // "image": "study_abroad",
      image: "e1",
      "heading": "Migrant Masters Study Abroad Seminar",
      "desc": [
        "Date & Timing: On 24th January 2024 at 2 pm",
        "The seminar covered studying abroad, including university selection, visa processes, and career opportunities.",
        "Speaker: Dr. Swapnil Kale",
        "Venue: Seminar Hall"
      ]
    },
    {
      // "image": "hackathon",
      image: "e1",
      "heading": "Codertine",
      "desc": [
        "Date & Timing: On 10th February 2024 at 12:15 pm",
        "'CodeTine' was an online hackathon hosted by Codecell, with participants competing to build projects within a set timeframe.",
        "Top 5 winners received cash prizes and all participants earned certificates.",
        "Venue: Online Platform"
      ]
    },
    {
      // "image": "alumni",
      image: "e1",
      "heading": "Alumni Unplugged",
      "desc": [
        "Date & Timing: On 16th February 2024 at 1 pm",
        "Alumni shared their experiences and insights on career paths, industry trends, and personal growth.",
        "Venue: Seminar Hall"
      ]
    },
    {
      // "image": "hackathon",
      image: "e1",
      "heading": "Recursion 5.0",
      "desc": [
        "Date & Timing: On 13th-14th March 2024 at 8 am",
        "A 24-hour offline hackathon challenging students to develop innovative solutions, with a prize pool for both junior and senior participants.",
        "Venue: Auditorium"
      ]
    },
    {
      // "image": "hackathon",
      image: "e1",
      "heading": "Decoding Hackathons",
      "desc": [
        "Date & Timing: On 29th August 2024 at 1:15 pm",
        "A panel of hackathon experts shared strategies, tips, and experiences on how to excel at prestigious hackathons.",
        "Venue: Seminar Hall"
      ]
    },
    {
      // "image": "valedictory",
      image: "e1",
      "heading": "Valedictory",
      "desc": [
        "Date & Timing: On 2nd September 2024 at 1:30 pm",
        "The CESS and Codecell valedictory function celebrated the achievements of the club members and bid farewell to graduating students.",
        "Venue: Seminar Hall"
      ]
    },
    {
      // "image": "cybersecurity",
      image: "e1",
      "heading": "Cybersecurity Seminar",
      "desc": [
        "Date & Timing: On 3rd September 2024 at 1:00 pm",
        "Topics included securing networks, operating systems, and social networking site security.",
        "Speaker: Mr. Azhad Shaikh",
        "Venue: Seminar Hall"
      ]
    },
    {
      // "image": "web_dev",
      image: "e1",
      "heading": "Web Development Bootcamp",
      "desc": [
        "Date & Timing: On 19th & 20th September 2024 at 1:15 pm",
        "The bootcamp focused on HTML & CSS fundamentals, Tailwind CSS, and building a portfolio website.",
        "Speaker: Mr. Dhirendra Lohar",
        "Venue: Third Floor, RGIT"
      ]
    },
    {
      // "image": "innovation",
      "image": "e1",
      "heading": "Accenture Innovation Bootcamp",
      "desc": [
        "Date & Timing: On 21st September 2024 at 2 pm",
        "This bootcamp introduced participants to job opportunities, the Accenture Innovation Challenge, and provided networking opportunities with industry leaders.",
        "Mode: Online"
      ]
    },
    {
      image: "e1",
      heading: "Career Guidance Seminar on Abroad Studies",
      desc: [
        "Date: 14th September 2023",
        "Time: 9 am onwards",
        "Join us for a Career Guidance seminar on Abroad Studies with Knowledge Reach!",
        "About Speaker: Purvi C Tolia",
        "Venue: Seminar Hall (1st floor)",
      ],
    },
    {
      image: "e2",
      heading: "Data Science Workshop",
      desc: [
        "Date: 12th September 2023",
        "All aspiring data scientists, get ready for an enlightening and fun Data Science workshop!",
        "About Speaker: Kareem Shah",
        "Data scientist at Infodesk Computer Education.",
        "Google Certified IT professional with 5+ years of experience, also worked in the Education sector.",
        "Anticipating to implement professional skills to enhance the organization and create a successful career.",
        "Time: 8:30 am onwards",
        "Venue: Seminar Hall, RGIT.",
      ],
    },
    {
      image: "e3",
      heading: "Python Bootcamp - Unleash the Power of Programming",
      desc: [
        "Date: 31st August",
        "Join us at the Python Bootcamp to unravel the power of programming!",
        "Master Python basics and syntax, explore Numpy for number crunching, dive into data analysis with Pandas, and visualize insights using Matplotlib.",
        "Hands-on project to showcase your skills.",
      ],
    },
    {
      image: "valedictory",
      heading: "Valedictory Ceremony",
      desc: [
        "Cheers to new beginnings and memorable farewells!🎓✨ ",

        "Join us in honouring our outstanding seniors by reminiscing the beautiful memories and moments made together as they embark on their bright and promising futures✨",
        "🎓Date: 31st July, 2023",
        "🕰️Time: 2 pm",
        "✨Venue: Seminar Hall",
      ],
    },
    {
      image: "cube",
      heading: "Crack the Cube - Cube Solving Competition",
      desc: [
        "Date: 23rd March 2023 and 24th March 2023",
        "Get ready to showcase your power moves on the cube at CRACK THE CUBE!",
        "Top 3 fastest solvers win amazing voucher prizes",
        "Rule of the event: The top 3 fastest to solve the cube in less time are the winners.",
        "Registration Fee: 40 Rs",
        "Venue: RGIT Cafeteria",
        "Time: Whole day",
      ],
    },
    {
      image: "jenga",
      heading: "Icarus Fest 2023 - Giant Jenga",
      desc: [
        "Date: 23rd March 2023",
        "Get ready for ICARUS FEST 2023!",
        "CESS in collaboration with STUDENT COUNCIL presents GIANT JENGA",
        "Stand out with your gaming skills and win exciting prizes",
        "Entry Fee: ONLY 40/- per person",
      ],
    },
    {
      image: "recursion",
      heading: "Recursion 4.0 - The Hackathon",
      desc: [
        "Date: 16th March 2023 to 17th March 2023",
        "Team up with 2-4 members and participate in the following domains:",
        "BLOCKCHAIN",
        "WEB/APP DEVELOPMENT",
        "AI/ML",
        "Prizes:",
        "25K cash + 10K worth vouchers",
        "15K cash + 5K worth vouchers",
        "5K worth vouchers",
        "Time: 24 HRS",
        "Fee: Free",
        "Venue: Rajiv Gandhi Institute of Technology, Andheri West, Mumbai.",
        "Last date to register is 13th March, 2023",
      ],
    },
    {
      image: "abroad",
      heading: "global opportunities",
      desc: [
        "Date: 16th February 2023",
        "Ready to take your education to the next level?",
        "Discover the world of global opportunities at Rajiv Gandhi Institute of Technology.",
        "Learn about the best study abroad programs and take the first step towards realizing your dreams.",
        "Don't miss out on this once-in-a-lifetime experience!",
      ],
    },
    {
      image: "global",
      heading: "Global EduFest 2023",
      desc: [
        "Date: 5th February 2023 (Mumbai), 8th February 2023 (Pune), 10th February 2023 (Hyderabad)",
        "Meet 60+ world-class universities in person from Australia, Canada, Ireland, United Kingdom, United States of America, and New Zealand at the Global EduFest 2023.",
        "Event Locations:",
        "Mumbai - 5th February 2023 at Westin Mumbai.",
        "Pune - 8th February 2023 at AISSMS College of Engineering Pune.",
        "Hyderabad - 10th February 2023 at T-Hub Hyderabad.",
      ],
    },
    {
      image: "Globalop",
      heading: "Seminar on Abroad Studies with Global Opportunities",
      desc: [
        "Date: 25th January 2023",
        "Time: 10:00 am - 11:30 am",
        "RGIT-CESS brings you a seminar on Abroad Studies with Global Opportunities - Leading Overseas Education Consultants.",
        "About Speaker: Gunjan Arora",
        "She has completed her MBA in HR & Finance and has worked in the Education Sector for the past 8 years.",
        "Countries of specialization include UK, Canada, Europe, USA & Australia.",
        "Venue: Seminar Hall",
      ],
    },
    {
      image: "life",
      heading: "Expert Talk on Life After Engineering",
      desc: [
        "Date: 22nd September 2022",
        "Time: 10 AM",
        "RGIT CESS & CSI present an Expert Talk on 'Life after Engineering,' specially curated for the students of RGIT.",
        "MTech in India",
        "Masters in a foreign country",
        "Package breakdown",
        "Expert: Mrs. Deepali Shinde, a software engineer with 4 years of experience looking for impactful and challenging projects to grow professionally and add value to businesses.",
        "Venue: Seminar Hall, RGIT",
      ],
    },
    {
      image: "dataanalytics",
      heading: "Expert Talk On Data Analytics",
      desc: [
        "Date: 12th August 2022",
        "Time: 9:30 am to 12:00 pm",
        "Cess and Computer Engineering Alumni Committee are happy to announce an expert talk on Data Analytics by Mr. Rakesh Raut.",
        "Mr. Rakesh Raut is an alumni and is currently the Practice Delivery Lead in the South East Asia Region at Wipro.",
        "Venue: Seminar Hall",
      ],
    },
    {
      image: "computertrends",
      heading: "Offline Seminar on Latest Trends in Computer Engineering",
      desc: [
        "Date: 16th March 2022",
        "Time: 10am-1pm",
        "Rgit Cess presents a seminar in collaboration with IIC (Institutions Innovation Council).",
        "Computer science offers a sought-after, lucrative career path for tech-savvy people interested in the latest computer advancements.",
        "Speaker: Ranjeet Walunj, Vice-President (Clever tap, Mumbai)",
        "Venue: RGIT Seminar Hall",
      ],
    },
    {
      image: "gre",
      heading: "Webinar on Study Abroad & GRE Scholarship Test",
      desc: [
        "Date: 15th March 2022",
        "Time: 4pm-5pm",
        "RGIT CESS & CSI present a Webinar on Study Abroad & GRE Scholarship Test.",
        "Speakers:",
        "Rahul Bhat (Sr. Counsellor and Trainer/Head)",
        "Anil Kulkarni (Verbal Trainer/ Counsellor)",
      ],
    },
    {
      image: "projectdrive",
      heading: "Project Drive Competition",
      desc: [
        "Deadline to fill the form is 17 March 2022 (12pm in afternoon).",
        "RGIT CESS presents a Project Drive Competition.",
        "Open to all years of the computer department.",
        "Team size (1 to 4 members)",
        "Categories: Hardware, Software",
        "Software project domains: Web / App / AR / VR / ML / AI / Blockchain / System design",
        "Hardware project domains: IOT / Real-world models / Simulation / Robotics",
        "Entry FEE: Rs. 25/- (for individual as well as group entries)",
      ],
    },
    {
      image: "cloud",
      heading: "Cloud Computing Workshop",
      desc: [
        "Schedule:",
        "10/4/2022, Thursday, 2:00 pm to 4:00 pm",
        "11/4/2022, Friday, 2:00 pm to 4:00 pm",
        "12/4/2022, Saturday, 2:00 pm to 4:00 pm",
        "Prof. Yogesh Jadhav.",
        "He is a professor at NMIMS, Mumbai. He is also a corporate trainer and an NS3 developer. He has written and published several research papers as well.",
      ],
    },
    {
      image: "foreignexchange",
      heading:
        "WEBINAR ON FOREIGN EXCHANGE - Introduction to Forex Currency and Trading",
      desc: [
        "Date & Timing: On 31st January at 1pm",
        "RGIT CESS presents a FREE webinar on the topic 'Introduction to Forex Currency and Trading.'",
        "Topics to be discussed:",
        "What is forex?",
        "How it is Traded",
        "Speakers for the day:",
        "Ms. Harshita (Research expert from OTI) and Mr. Nivesh (Onboarding Trading Trainers From Xflowmarkets)",
      ],
    },
    {
      image: "dsa",
      heading: "DSA Webinar - Road map to Dream Company",
      desc: [
        "Date & Timing: On 11th December 2021 at 5pm",
        "RGIT CESS and CODECELL presents a FREE DSA webinar on the topic 'Road map to Dream Company.'",
        "A full-fledged roadmap for placement",
        "Do's and Dont's",
        "Doubt-solving session",
        "Speaker: Ms. Kirti Gera",
        "Ms. Kirti Gera from NIT, Jamshedpur, is currently working as a Course Mentor at geeksforgeeks.",
      ],
    },
    {
      image: "codertine",
      heading: "CODERTINE 2.0",
      desc: [
        "Event date: 13th Nov, 2021",
        "DETAILS of the competition:",
        "Time: 11:00 am to 1:30 pm IST",
        "Last date of Registration: 11th Nov, 2021",
        "Platform: Coding Blocks Coding platform - Hacker blocks",
        "Mode: Online",
        "All the standard languages are accepted for the coding contest.",
        "Certificate of participation.",
        "Winners stand a chance to win goodies from geek for geeks depending on their overall performance.",
      ],
    },
    {
      image: "google",
      heading: "Google Cloud Career Readiness Program RGIT",
      desc: [
        "Date: 14th November 2021, Sunday",
        "The entire program is free of cost, open to dedicated participants.",
        "- FREE ACCESS to 'Architecting with Google Cloud Platform'",
        "- FREE ACCESS to 'Cloud Engineering Quest' and additional self-paced labs on Qwiklabs.",
        "- 50% discount on the official Google Cloud Associate Engineering Certification exam for all students.",
      ],
    },
    {
      image: "endeavour",
      heading: "Endeavour: How to Crack GRE and Get into Your Dream University",
      desc: [
        "Date: 4th October 2021",
        "CESS, in collaboration with Endeavour, presents a seminar on how to crack GRE and achieve admission to your dream university.",
      ],
    },

    {
      image: "entrepreneur",
      heading: "Want to start your own business?",
      desc: [
        "Date: 02/09/2021",
        "Don't know how to start your own business?",
        "Join us for an Entrepreneurship webinar by CESS, presented by our Alumni Mr. Niket Sarvaiya, co-founder and COO of Picostone.",
      ],
    },
    {
      image: "A1",
      heading: "Applied Statistics for Data Science - Techniques and ",
      desc: [
        "Date: 22nd August 2021 ",
        'In association with "AI PROBABLY”, CESS presented an online Webinar /Workshop on "Applied Statistics for Data Science - Techniques and concepts". ',

        "Parth Sharma (Chief Data Scientist at Jasper Colin Research) and Vanishri Balnad (Director and Chief business officer at AIPROBABLY) had taken such an informative and interactive workshop.",
      ],
    },
    {
      image: "A2",
      heading: "Webinar on Cyber Security ",
      desc: [
        "Date: 28 August 2021",
        "To get a deeper understanding, we had invited Cyber security expert Mr. Saikumar M to share his knowledge with us",

        "Mr. Saikumar M, a RGIT alumni from the 2000 batch has over 20 years of rich professional experience in the IT industry in the areas of Business Development, Technical Sales in domains of Network and Security, Data Center, Virtualization, HCI, Cloud Computing, SDWAN. ",
      ],
    },
    {
      image: "A3",
      heading: "Entrepreneurship webinar ",
      desc: [
        "Date: 2 nd September 2021",
        "Best solution developed gets Rs. 6,000/team",
        "RGIT CESS helped the students explore the scope of entrepreneurship with the guidance of Mr. Niket Sarvaiya. ",

        "It was a session full of life and teachings. ",
      ],
    },
    {
      image: "A4",
      heading: "Webinar on machine learning ",
      desc: [
        "Date: 15th September 2021 ",
        "About the Webinar Machine learning is one of the most powerful technologies driving digital transformation today. It’s a constantly evolving field, and ML practitioners need to keep up with new techniques, tools, and algorithms to stay competitive.",
      ],
    },
    {
      image: "A5",
      heading: "Codertine 2.0",
      desc: [
        "Date: 13th November 2021",
        "It was 2.5-hour long coding challenge open to all coding enthusiasts to compete for a wide variety of opportunities and prizes. ",
        "Winners",
        "Rank1: Aniket Chauhan",
        "Rank2: Yogita Chaudhari",
        "Rank3: Jimit Jain",
      ],
    },
    {
      image: "1",
      heading: "A chance to interact with our very own Alumni 🎯",
      desc: [
        "Date: 8th May,2021",

        "  Mr. Amit Patil is a Sr. Design manager and has been a User Experience professional for the last 14 years. Currently managing a team of designers in Bangalore and is also a member of the board of studies at MIT Design, Pune.",

        " What to take from this",

        " ✏How should I plan for placements ?",

        " ✏What are the requirements to get placed in a product based company ?",

        "  ✏What options do I have once I’m done with my Engineering?",
      ],
    },
    {
      image: "A6",
      heading: "CESS Got Talent",
      desc: [
        "Date: 22 July,2021",

        " We are happy to present before you Cess got talent We intend to showcase the talents of our student committee, who are enrich in talent and charismas Anyone who has any talent they would like us to feature on our official CESS Instagram page, kindly send us the photo or video of the same.",

        " It could be",
        " Dance",
        " Singing",
        " Playing instruments",
        " Poems",
        " Artistic designs and much more.",
      ],
    },
    {
      image: "A7",
      heading: "Recursion 2.0 Online Hackathon",
      desc: [
        "Date: 22nd July,2021",

        " RGIT CodeCell and RGIT CESS presents RECURSION 2.0, a 24 hour Hackathon where all the teams will ideate to find suitable solutions for the problem statements they will be presented with.",

        " Explore your potential & connect with enthusiasts like you!",
        " Prizes Worth : 🏆",
        " 🥇1st Prize: Rs. 33,000",
        " 🥈2nd Prize: Rs. 30,000",
        "🥉3rd Prize: Rs. 28,000",
      ],
    },
    {
      image: "A8",
      heading: "Webinar on Foreign studies and job prospects",
      desc: [
        "Date: 12th July,2021",

        "Live interactive session on foreign education and job prospects by experts",

        " Assitance in applying in foreign colleges, visa, Scholarship, loan, accomodation,placement etc ",
      ],
    },
    {
      image: "A9",
      heading: "Endeavor GRE Webinar",
      desc: [
        "Date: 22nd July,2021",

        " CESS in collaboration with Endeavour classes is bringing to you a FREE seminar on how to plan your entrances and get into your desired universities by cracking the GRE exam💯",

        " 🔸About GRE : The Graduate Record Examinations is conducted by the Educational Testing Service (ETS). It is a standardized test that needs to be taken in order to get admission into graduate schools in the United States🇺🇸 and Canada🇨🇦.",

        "  To get deeper understanding about this GRE examination, we have invited an esteemed speaker to share his knowledge with us, Alok Bansal Sir.",
      ],
    },
    {
      image: "B1",
      heading: "Webinar on Data Science career and job prospects",
      desc: [
        "Date: 6 Feb,2021",

        "▫️1.5 Hours live interactive session on Career Development by Experts",

        " ▫️Gain Data science career Program and Future Job Prospects",

        " ▫️Campus Placement Drives / Hiring Matrix and Placent Opportunities",

        "▫️Global Certification +  Standard set of Modules Certification details",

        " ▫️Post to the webinar, customise one-On-one counselling sessions on course and career planning with experts",

        " ▫️Guranteed Placement estimation/Job Refund policy ",
      ],
    },
    {
      image: "B2",
      heading: "Codertine 2020",
      desc: [
        "Date: 31 october,2021",
        "RGIT Codecell  along with  CESS  is conducting an online inter-college coding competition –  CODERTINE  on  Codechef , where students will be provided with problem statements that will revolve around the concepts of  Competitive Programming  .",
        " Prizes worth upto  –",

        " 🥇1st place: Rs.2500",

        " 🥈2nd place:Rs.1500",

        " 🥉3rd place:Rs.1000",
      ],
    },
    {
      image: "B3",
      heading: "Career Councelling",
      desc: [
        "Date: 11th october ,2021",
        " Learning edge  in collaboration with  Codecell  and  CESS  is holding a career counseling session.  All your doubts about exams, admissions, visa process and more will be cleared.",

        " The speakers for this will be  Mr.Pankaj Mehta  who has successfully completed his Master’s degree in Commerce from the prestigious",

        " University of Mumbai and thereafter gained tremendous experience in the consulting area which runs to over two decades now.",

        " The second speaker is  Mr.Manoj Kansara  who has been consulting for nearly 19 years now and have always received appraisals from all his students and their parents.",
      ],
    },
    {
      image: "B4",
      heading: "Google Cloud Platform Webinar",
      desc: [
        "Date: 3 october, 2021",
        "Code for Cause is an initiative started by a group of like-minded people working for a similar cause. Their primary focus is to provide guidance and mentorship to students. Not only for those who lack on-campus opportunities but also for those who lack awareness about the possibilities in the field. They provide a hands-on learning experience and keep students informed about the latest trends in technology.",

        "The webinar will help us understand the usefulness of GCP compared to its other counterparts. We will get to understand how infrastructure as a service (IaaS), platform as a service (PaaS), and software as a service (SaaS). ",
      ],
    },
    {
      image: "B5",
      heading: "Front end web development",
      desc: [
        "Date: 3 October,2021",
        ":Our speaker will be  Nitish Talekar  who is one of the founders of  Codecell . He was also the Technical secretary of RGIT. He is well versed with front end development.",

        "You will get to know all about how to deal with the front end of any web project you take on.",

        " Apart from that, you will get to know about the technologies that you should use to make it seem more efficient. You will also get to know about how should you go about the UI and UX components of the project you choose.",
      ],
    },
    {
      image: "B6",
      heading: "RGIT Alumini presents Student’s guide to the capstone project",
      desc: [
        "Date: 2 October,2021",
        "CESS  is holding a webinar on  A student’s guide to approach final year capstone project . This webinar aims to help students understand how to choose and effectively plan their capstone projects.",

        " What is a capstone project? ",

        " Capstone projects are a two-semester process in which students pursue independent research on a question or problem of their choice. During this, they receive guidance from a mentor from the faculty.",
      ],
    },
    {
      image: "fresher",
      heading: "Cloud Computing Seminar",
      desc: [
        "Date: 4 Feb, 2020",
        "The seminar was conducted from 9:00 a.m. to 11:00 am. on Thursday in the Seminar Hall. There were more than 60 students that participated in this session. The HOD of the Computer Engineering Department, Mr. Satish Ket, felicitated the speaker of the session, Mrs. Priyanka Sawant, an expert who has been in the industry for more than 19 years. She is currently a Product Manager at Belfrics Cryptex Pvt Ltd.",
        "The seminar commenced with the speaker talking about the basics of Cloud Computing and its applications in the real world.",
      ],
    },
    {
      image: "girl",
      heading: "Higher Studies",
      desc: [
        "Date: 17 october,2019",
        "The seminar was conducted in SE and TE classrooms,on both Wednesday and Thursday. There were more than 60 students in each class that participated in this session. The speakers were Mr. Ajit Singh, who is the Head of International Exams at Endeavor, and Mr. Sazeal Shah, who is an Entrepreneur and a Trainer. He is the center director of Endeavorin Gandhinagar.",

        " Mr. Ajit Singhhas been in the education industry for more than ten years. He has already aced all the exams for which he mentors his students, therefore his knowledge gives an in-depth understanding of both, the content and the testing pattern of these tests. Mr. Sazeal Shahis involved in training and development as a career mentor for the students. He is an expert lecturer in a plethora of subjects and is popular for delivering the “Value Sessions”. ",
      ],
    },
    {
      image: "consolation",
      heading: "Data Science work shop",
      desc: [
        "Date: 10 october,2019",
        "CESS, in collaboration with ASME, organized a workshop on Data Science. The workshop helped students in understanding the basics of Data Science and its industrial applications. The workshop was conducted in the seminar hall, on both Wednesday and Thursday. There were more than 50 students who enrolled for the workshop. The president of CESS, Mr.Saad Ghojaria, and the Chairperson of ASME, Mr.Rutuj Jagtap, felicitated the speaker, Mr.Ashwin Mehta, who is an expert in the Data Science field. He holds a PhD in Physics, from Bhabha Atomic Research Centre (BARC) and is a Technical Director at Vissicomp. The workshop commenced with the speaker talking about Python and where it is required. After that, he informed the students about what will be covered in the workshop over the duration of two days.  ",
      ],
    },
    {
      image: "B7",
      heading: "FACEBOOK DEVELOPER’S CIRCLE – IDEATHON",
      desc: [
        "Date: 30 sept,2019",
        "The CESS organized a seminar cum workshop activity under Facebook Developer Circle – Ideathon, guidance. DevC from Facebook is a program designed to create locally organised communities for developers. This program was conducted on 30th September, 2019 under the Guidance of Yash Dahenkar is Co-Founder and Director of Bitgenie. There were more than 150 students who has given active participation in activities. The mission and vision to create products using Web 3.0 technologies. He is in Blockchain space since 2016 and has been an avid investor himself. HOD Prof. Satish Ket felicitate the honourable guest and program begin with enthusiastic participation of students. An ideathon is generally defined as a short, intensive, workshop-like experience for people to address issues and/or challenges. ",
      ],
    },

    {
      image: "B9",
      heading: "Machine Learning and Artificial Intelligence Seminar",
      desc: [
        "Date: 21 August,2019",
        "The CESS committee organized a seminar on Artificial Intelligence and Machine Learning. The seminar helped students in understanding the basics of Machine Learning and Artificial Intelligence, and how to use IBM cloud for the same The seminar was conducted from 10:30 a.m. to 12:30 p.m. on 21th August 2019, Wednesday in the Seminar Hall. There were more than 60 students that participated in this session. The HOD of the Computer Engineering Department, Mr. Satish Ket, felicitated the speaker of the session, Mr. Mangesh Patankar, an expert who has worked with several prominent companies such as Syntel, CCG, Oracle, and has been in the industry for nearly 20 years. He is currently a Developer Advocate at IBM India. The seminar commenced with the speaker talking about the basics of Machine Learning, Artificial Intelligence, Data Science and their applications in the real world. After the brief introduction, the focus shifted to IBM cloud and how it helps in various projects, especially those related to Machine Learning and Artificial Intelligence",
      ],
    },

    {
      image: "B8",
      heading: "Facebook Developer Circle- Build Day",
      desc: [
        "Date: 9 August,2019",
        "The students of computer department along with the members of CESS committee took part in the Build Day organized by Facebook Developers Circles. The students participated in the event with great enthusiasm. The main objective of the event was to get the developers to come together form teams and codes, and to create projects for a challenge. Students were introduced to the Developer Circle Community, Challenges and projects. The students were guided by Mr. Saad Ghojaria, Mr. Aayush Singh and Mr. Nitish Talekar, senior committee members, CESS. The event was conducted on 9 th of August from 5.30 p.m. till 7.30 p.m. at RIIDL, Bhaskaracharya building, Somaiya Vidyavihar, Mumbai. It was organized for the S.E, T.E. and B.E. students",
      ],
    },
    {
      image: "C2",
      heading: "Gate Seminar",
      desc: [
        "Date: 30 July,2019",
        "The CESS organized a seminar on how to crack the ‘GATE EXAM’ for the Computer Department students, held on 30th July 2019. The objective was to guide us about the process and to understand and examine various engineering and science undergraduate subjects to the students. The mentor of the seminar was Hon Prof. Rajesh Singh Vats (Ex Scientist Prime Minister of India) B.tech, M.tech, MBA from NITIE Mumbai. The seminar was conducted from 9:00 a.m. to 11:00 a.m. on Tuesday in the Seminar Hall for SE, T.E., and B.E. students. There were more than 85 students who participated in this session",
      ],
    },

    {
      image: "C1",
      heading: "SE Induction",
      desc: [
        "Date: 18 July,2019",
        "The CESS committee organized an induction for the Second Year Computer Department Students,held on 18th July. Theobjective was to inform the students about the academic and extra-curricular activities and the workshops which will be held for them in the following year. The orientation was being conducted from 2:00 to 4:00 pm in the seminar hall by T.E. and B.E. students. There were about 100-120 students present for the induction. The Induction started with felicitation of our Head of the Department, Dr. Satish Ket Sir by President Saad Ghojaria, Founder of CESS Prof. Sunil Khachne was felicitated by Vice President Deepali Zutshi , CESS coordinator Prof. Dilip Dalgade was felicitated by Vice President Mohit Sawant.",
      ],
    },

    {
      image: "matic",
      heading: "SUMMER INTERNSHIP 2019",
      desc: [
        "Date: 30 sept,2019",

        "The CESS committee organized an Internship Program for the First year/ Second year/ Third year as well as Final year engineering students of our college, held in the month of June. The objective was to provide an amazing Internship opportunity to maximum number of students.",

        " The internship was offered in CATKing, Mumbai. CATKing is a Focused Ed – Tech Startup established in 2008. Located in the Financial Capital of India Mumbai is a one-stop destination for each MBA/MS aspirant that focuses on strategic, engaging and high-quality education.",

        " The duration of the internship was one month with a good amount of stipend. The offered profile was Business Development, Executive Entailing Sales and Marketing Role with required skills in English Proficiency, dedication and sincerity.",
      ],
    },
    {
      image: "B9",
      heading: "Machine Learning and Arificial Intellegance Seminar",
      desc: [
        "Date: 13 Feb,2019",

        "The CESS organized Machine Learning & Artificial Intelligence Seminar fo the Computer Department students, held on 13th Feb, 2019. The objective was to impart the very idea of ML and AI to the students, how to start from scratch, all the way up to learning its practical scenario. The mentor of the workshop was Mr. Kush Hingol, B.E student of RGIT. He along with his team, won the best paper award at a conference held in Sai Vidya Institute of technology, Bangalore. They presented on Smart Assistant for Doctors which is a disease prediction software.",
        "The seminar was conducted from 9:30 a.m. to 12 p.m. on Wednesday in the Seminar Hall for T.E. and B.E. students. There were approximately 60 students who participated in this session. The seminar started with the brief introduction by our host and felicitation of Prof. Jyoti Deshmukh.",
      ],
    },
    {
      image: "C4",
      heading: "Artificial Intelligence Invited Talk",
      desc: [
        "Date: 16 Jan,2019",
        "The CESS organized Artificial Intelligence Invited Talk for the Computer Department students held on 16th Jan 2019. The objective was to impart the very idea of AI to the students, how to start from scratch, all the way up to learning its practical scenario. The mentor of the workshop was Oracle and Microsoft certified Mr. Nitish Mishra.",
        "he session began with the basics of understanding the abstract layers of AI. A brief introduction to Machine learning, Data Science, Big Data was provided. After that, the seminar moved towards understanding the importance of programming languages and the various easier alternatives that could be chosen. During the session, a home automation kit was demonstrated along with Alexa. ",
      ],
    },

    {
      image: "C5",
      heading: "A blockchain semantics Invited talk",
      desc: [
        "Date:  26 Oct,2018",
        "A blockchain semantics talk was held on 18th January 2018 by the CESS committee. The objective of the seminar was to make the students aware of the current booming technology ‘Cryptocurrency’. The seminar was conducted from 10:30 am onwards in the seminar hall by the TE’s and BE’s. There were approximately 90-100 students present for the seminar.",

        "Chinmaya Sharma, who graduated from IIT Kharagpur and IIM Bangalore presided over the session. He started off by narrating his educational details, his college life and how he ended up co-founding Blockchain Semantics. His company basically offers courses on understanding blockchain and learning to code on that platform.",
      ],
    },
    {
      image: "C6",
      heading: "web development workshop",
      desc: [
        "Date: 20 July,2018",

        "The first day of the workshop started with the basics of HTML and CSS. Yash Badani from the technical team taught the students to build simple web pages and create attractive webpages with the help of html and css.",

        "The second day of the workshop was to teach students about the basics of Javascript. The day started with Seher Khan ,from the technical team brushing up about whatever was taught about html and css on day one. After the students were comfortable with the basics, they were made to understand the need for javascript and its proper implication in their webpages or websites.",
      ],
    },

    {
      image: "C7",
      heading: "Industrial Visit Report",
      desc: [
        "Date: 6 March,2018",
        "The industry we visited was I-medita : Cisco Networking Labs, which is located in Hinjawadi, Pune. After reaching the facility students were greeted and guided by one of the staff of the labs. We always imagined that a networking lab would be a small office with minimal workforce and efforts required but it was great to see and know that it is not true. The industrial visit was divided into two sections theoretical and practical. In theory session, we were introduced about the company I-medita along with the different courses provided by them. They provided us with a booklet that included information about various alumni of the company, the courses they offered, and relevant data regarding the same. We were then showed a ppt which consisted of a lot of interesting facts and information. The revenues of different companies were also acquainted with us. The revenue of the Cisco company alone is 4800.5 crore USD which is quite amazing to know. We were briefed about how networking as a carrier is now foreseen as a bright future. The theory session included more of such information regarding networking and how it has evolved with time. It was an interactive two-way session in which the professor asked questions to students and also solved various doubts of the students. The theory session was thus concluded",
      ],
    },
    {
      image: "C8",
      heading: "DATA SCIENCE SEMINAR",
      desc: [
        "Date: 15 Feb,2018",
        "Abhishek Sawarkar, a data scientist at Arya.ai presided over the session. Arya.ai is a tech company that deploys deep learning to solve conventional challenges in various sectors. The company is renowned for being selected by Paris&co and for featuring in Forbes Asia’s 30 under 30. The topics covered by the speaker included the Journey of building an AI startup, products of Arya.ai and its functioning, challenges and approaches, tools, and skills required to enter these domains. Also, a Video Demo on advanced object detection was shown to the students and thus imparting significant knowledge about this field. The major highlight of the seminar was the special Internship opportunity for the students.",
      ],
    },

    {
      image: "C9",
      heading: "Wordpress workshop",
      desc: [
        "Date: 8 Feb, 2018",
        " Hands-on WordPress workshop was held on 8th February 2018 by the CESS committee. The objective of the workshop was to make the students aware of ‘WORDPRESS’, an online, open-source website creation tool. Basically the easiest and most powerful blogging and website content management system (CMS) in existence today. It is being used by more than 29.4% of the top 10 million websites as of January 2018 hence making it the most popular CMS. The 6 hrs. workshop was conducted by Ibrahim Rumani, the owner & founder of Vocational Academy along with the CESS members of TE and BE. There were approximately 60-70 students present for the workshop.",
      ],
    },
    {
      image: "C5",
      heading: "A blockchain  Seminar",
      desc: [
        "Date: 18 Jan, 2018",
        "A blockchain semantics talk was held on 18th January 2018 by the CESS committee. The objective of the seminar was to make the students aware of the current booming technology ‘Cryptocurrency’. The seminar was conducted from 10:30 am onwards in the seminar hall by the TE’s and BE’s. There were approximately 90-100 students present for the seminar. Chinmaya Sharma, who graduated from IIT Kharagpur and IIM Bangalore presided over the session. He started off by narrating his educational details, his college life and how he ended up co-founding Blockchain Semantics. His company basically offers courses on understanding blockchain and learning to code on that platform. The seminar gathered a significant number of students and proved to be very informative for the students who wanted to explore in this field of cryptography.",
      ],
    },
    {
      image: "prototype",
      heading: "LATEX WORKSHOP",
      desc: [
        "Date: 30 oct,2017",
        "The workshop started with the presence of HOD Ket sir, Class Advisor, Ms. Priya. A short introduction of Mr. Sanjoy Shitole and his expertise in LaTeX was given by Mr. Rishi Wadekar in beginning. Mr. Sanjoy Shitole started with telling students about various drawbacks of using MS Word or any other editor and how LateX can solve them. He initially made students familiar with the basic functioning of TeX Studio and later on started with the actual documentation. He also informed students about how LaTeX supports various document formats. He demonstrated how LaTeX auto numbers mathematical equations which are not possible in MS Word. The students documented a report in LaTeX under his guidance during the workshop. ",
      ],
    },
    {
      image: "D1",
      heading: "Soft skils Seminar",
      desc: [
        "Date: 4 oct,2017",
        "A Seminar for the Third and Fourth year Computer department students was held on 4 October 2017 by the CESS committee. The objective of the seminar was to guide the students about the project management and also the skills & activities required for job interview. The seminar was conducted from 9.30am-12.30pm in the seminar hall for the TE’s and BE’s. There were approximately 100-120 students present for the orientation.",

        "The seminar started with the presence of HOD Keth Sir and Suresh Sir. The seminar was conducted by Mr. Shubho Ghoshal. The students were informed about the project management strategies through power point presentation.The information regarding project management like project initiation, project planning, project execution, project closure, etc was displayed in the presentation.",
      ],
    },
    {
      image: "D2",
      heading: "Android Devlopment Workshop",
      desc: [
        "Date: 9 March,2015",
        "Seminars provide a great opportunity for students to learn about the new developments in the technological world and also allows them to interact with industrial professionals. They provide a guiding light towards a better career, and inform students about their future prospects. Keeping in this mind, the Department of Computer Engineering has always given importance to conducting seminars and workshops for the students. It helps in the overall growth and polishing of the students.",

        "Considering the rapid growth in mobile app development and web development, Computer Engineering Students’ Society (CESS) arranged a seminar on “OPEN SOURCE TECHNOLOGIES- ANDROID AND WEB DEVELOPMENT” on 09th March, 2015. This seminar was conducted by Suven Consultants and Technology Pvt. Ltd.,",
      ],
    },
  ];
  return (
    <div className="margintop">
      <Typography
        variant="h3"
        align="center"
        component="p"
        className="dsc-font"
        data-aos="flip-left"
      >
        <span>Events</span>
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        alignContent="center"
        data-aos="flip-up"
      >
        {prizeArr.map((val) => (
          <Grid
            xs={12}
            md={4}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
            spacing={10}
            className="margintop flexdisp"
          >
            <Prizecard
              image={val.image}
              head={val.heading}
              desc={val.desc}
              isDark={props.isDark}
            />
          </Grid>
        ))}
        {/* <Typography
                    variant="h5"
                    align="center"
                    component="p"
                    className="dsc-font"
                    data-aos="flip-left"
                    style={{padding: "50px 0px"}}
                    >
                        Coming Soon
                </Typography> */}
      </Grid>
    </div>
  );
}
